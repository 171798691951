export const getMegaMenuBadgeColor = (value) => {
    let result = "";
    switch (value) {
        case "New":
            result = "success";
            break;
        case "Hot":
            result = "danger";
            break;
        default:
            break;
    }
    return result;
};
