import capitalize from "lodash/capitalize";
import { useState, useEffect } from "react";
import styled from "styled-components";

export const Badge = ({ value = "", type = "", variant = "success", className = "" }) => {
    const [color, setColor] = useState("");
    const [bgColor, setBgColor] = useState("");
    const [badgeType, setBadgeTye] = useState("");

    useEffect(() => {
        switch (variant) {
            case "success":
                setColor("#027A48");
                setBgColor("#D1FADF");
                break;
            case "danger":
                setColor("#B42318");
                setBgColor("#FEE4E2");
                break;
            case "warning":
                setColor("#B54708");
                setBgColor("#FEF0C7");
                break;
            default:
                break;
        }
    }, [variant]);

    useEffect(() => {
        switch (type) {
            case "square":
                setBadgeTye("jlt-rounded-sm");
                break;
            case "left-poligon":
                setBadgeTye("jlt-rounded");
                break;
            default:
                setBadgeTye("jlt-rounded-full");
                break;
        }
    }, [type]);

    return (
        <CustomBadge
            $customClass={className}
            $color={color}
            $bgColor={bgColor}
            $badgeType={badgeType}>
            {capitalize(value)}
            {type === "square" ? <i className="jlt_polygon" /> : null}
            {type === "left-poligon" ? <i className="jlt_left_poligon" /> : null}
        </CustomBadge>
    );
};

const CustomBadge = styled.span.attrs((props) => ({
    className: `jlt-relative jlt-text-sm jlt-font-bold jlt-py-[2px] jlt-px-2${
        props.$customClass ? ` ${props.$customClass}` : " "
    } ${props.$badgeType ? ` ${props.$badgeType}` : ""}`,
}))`
    ${(props) => (props.$color ? `color: ${props.$color}` : "")};
    ${(props) => (props.$bgColor ? `background: ${props.$bgColor}` : "")};

    > .jlt_polygon {
        position: absolute;
        top: 98%;
        transform: translateX(-50%);
        left: 11px;
        width: 22px;
        height: 5px;
        border: none;
        border-radius: 0;
        background: ${(props) => props.$bgColor};
        clip-path: polygon(22% 0, 98% 0, 25% 98%);
    }
    > .jlt_left_poligon {
        position: absolute;
        top: 98%;
        -webkit-transform: translateX(-50%) rotate(180deg);
        -ms-transform: translateX(-50%) rotate(180deg);
        -webkit-transform: translateX(-50%) rotate(180deg);
        -ms-transform: translateX(-50%) rotate(180deg);
        transform: translateX(-50%) rotate(180deg);
        top: 0;
        bottom: 0;
        margin: auto;
        left: -4px;
        width: 16px;
        height: 16px;
        border: none;
        border-radius: 0;
        background: ${(props) => props.$bgColor};
        -webkit-clip-path: polygon(11% 0, 100% 45%, 15% 98%);
        -webkit-clip-path: polygon(11% 0, 100% 50%, 15% 98%);
        clip-path: polygon(11% 0, 100% 50%, 15% 98%);
    }
`;
